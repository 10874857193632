<template>
  <div class="pa-5">
    <v-card class="card-style rounded-1" >
    <v-row class="ma-2">
      <v-col cols="12" md="9" class="flex-items">
        <h2>All Applications</h2>
      </v-col>
      <v-col cols="12" md="3">
      <v-text-field
          v-model="search"
          prepend-inner-icon="search"
          label="Search applications"
          single-line
          hide-details
          color="#38435a"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-card class="mb-1 dt-container" elevation="0" outlined>
    <v-data-table
      :loading="loading"
       :headers="headers"
        :items="data"
        item-key="id"
        :options.sync="options"
        hide-default-footer
    >
    <template v-slot:[`item.token`]="{ item }">
      <span class="text-no-wrap">{{item.token}}</span>
    </template>
    <template v-slot:[`item.applicant`]="{ item }">
      <span>{{(urlDecode(item.data.first_given_names || '')).toUpperCase()}} {{(urlDecode(item.data.family_names || '')).toUpperCase()}}</span>
    </template>
    <template v-slot:[`item.payment_date`]="{ item }">
      <span v-if="item.paid == 1 && item.payments" class="text-no-wrap">
        {{ formatDateTime2(item.payments.createdAtTimestamp) }}
        </span>
      <span v-else class="text-no-wrap">NOT YET PAID</span>
    </template>
    <template v-slot:[`item.type`]="{ item }">
      <span class="text-no-wrap">{{item.type}}</span>
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <div v-if="$store.state.user.role == 'agent'">
      <v-chip
        class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
        v-if="item.submitted == 1"
        small
      >
       <span>Completed</span>
      </v-chip>
      <span v-else>
        <span v-if="item.assignedTo == null || item.assignedTo == ''">
          <v-chip
          class="chip-status-2 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
          v-if="item.express == 1"
          small
        >
        <span>Express</span>
        </v-chip>
        <v-chip
          class="chip-status-3 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
          small
        >
        <span>Available</span>
        </v-chip>
        </span>
        <span v-else>
          <v-chip
          class="chip-status-2 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
          v-if="item.express == 1"
          small
        >
        <span>Express</span>
        </v-chip>
        <v-chip
          class="chip-status-4 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
          small
        >
        <span>Processing</span>
        </v-chip>
        </span>
      </span>
      </div>

      <div v-if="$store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'admin'">
      <v-chip
        class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
        v-if="item.submitted == 1"
        small
      >
       <span>Submitted</span>
      </v-chip>
      <span v-else>
        <span v-if="item.assignedTo == null || item.assignedTo == ''">
          <v-chip v-if="item.type == 'K-ETA' && item.data && item.data.photo_details"
              class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              small
            >
            <span>Photo</span>
          </v-chip>
          <v-chip v-else-if="item.type == 'K-ETA'"
              class="chip-status-5 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              small
            >
            <span>No Photo</span>
          </v-chip>
          <v-chip v-if="item.data.additional_info_submitted == 1 && item.type == 'USA-ESTA'"
              class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              small
            >
            <span>Travel Details Submitted</span>
          </v-chip>
          <v-chip v-else-if="item.data.additional_info_submitted == 0 && item.type == 'USA-ESTA'"
              class="chip-status-5 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              small
            >
            <span>Travel Details Pending</span>
          </v-chip>
          <v-chip v-if="item.passportValidationData && item.passportValidationData != '' && (item.type == 'USA-ESTA' || item.type == 'UK-EVW' || item.type == 'KH-EVISA' || item.type == 'VN-EVISA')"
              class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              small
            >
            <span>Passport Uploaded</span>
          </v-chip>
          <v-chip v-else-if="(item.type == 'USA-ESTA' || item.type == 'UK-EVW' || item.type == 'KH-EVISA' || item.type == 'VN-EVISA')"
              class="chip-status-5 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              small
            >
            <span>Pending Passport</span>
          </v-chip>
          <v-chip v-if="item.passportDataMatch == 0 && item.passportValidationData"
              class="chip-status-5 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              small
            >
            <span>Data Mismatch</span>
          </v-chip>
          <v-chip
              class="chip-status-2 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              small
              v-if="item.ticketCreated == 1"
            >
            <span>Awaiting Response</span>
            </v-chip>
          <v-chip
            class="chip-status-3 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
            small
            v-else
          >
          <span>Available</span>
          </v-chip>
        </span>
        <span v-else>
          <span v-if="item.ticketCreated == 1">
            <v-chip v-if="item.type == 'K-ETA' && item.data && item.data.photo_details"
              class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              small
            >
            <span>Photo</span>
          </v-chip>
          <v-chip v-else-if="item.type == 'K-ETA'"
              class="chip-status-5 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              small
            >
            <span>No Photo</span>
          </v-chip>
          <v-chip v-if="item.data.additional_info_submitted == 1 && item.type == 'USA-ESTA'"
              class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              small
            >
            <span>Travel Details Submitted</span>
          </v-chip>
          <v-chip v-else-if="item.data.additional_info_submitted == 0 && item.type == 'USA-ESTA'"
              class="chip-status-5 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              small
            >
            <span>Travel Details Pending</span>
          </v-chip>
            <v-chip v-if="item.passportValidationData && item.passportValidationData != '' && (item.type == 'USA-ESTA' || item.type == 'UK-EVW' || item.type == 'KH-EVISA' || item.type == 'VN-EVISA')"
              class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              small
            >
            <span>Passport Uploaded</span>
            </v-chip>
            <v-chip v-else-if="(item.type == 'USA-ESTA' || item.type == 'UK-EVW' || item.type == 'KH-EVISA' || item.type == 'VN-EVISA')"
                class="chip-status-5 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
                small
              >
              <span>Pending Passport</span>
            </v-chip>
            <v-chip v-if="item.issue != null"
                class="chip-status-5 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
                small
              >
              <span>Issue</span>
            </v-chip>
            <v-chip
              class="chip-status-2 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              small
            >
            <span>Awaiting Response</span>
            </v-chip>
          </span>
          
          <span v-else>
            <v-chip v-if="item.type == 'K-ETA' && item.data && item.data.photo_details"
              class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              small
            >
              <span>Photo</span>
            </v-chip>
            <v-chip v-else-if="item.type == 'K-ETA'"
                class="chip-status-5 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
                small
              >
              <span>No Photo</span>
            </v-chip>
            <v-chip v-if="item.data.additional_info_submitted == 1 && item.type == 'USA-ESTA'"
                class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
                small
              >
              <span>Travel Details Submitted</span>
            </v-chip>
            <v-chip v-else-if="item.data.additional_info_submitted == 0 && item.type == 'USA-ESTA'"
                class="chip-status-5 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
                small
              >
              <span>Travel Details Pending</span>
            </v-chip>
            <v-chip v-if="item.passportValidationData && item.passportValidationData != '' && (item.type == 'USA-ESTA' || item.type == 'UK-EVW' || item.type == 'KH-EVISA' || item.type == 'VN-EVISA')"
                class="chip-status-1 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
                small
              >
              <span>Passport Uploaded</span>
            </v-chip>
            <v-chip v-else-if="(item.type == 'USA-ESTA' || item.type == 'UK-EVW' || item.type == 'KH-EVISA' || item.type == 'VN-EVISA')"
                class="chip-status-5 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
                small
              >
              <span>Pending Passport</span>
            </v-chip>
            <v-chip v-if="item.issue != null"
                class="chip-status-5 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
                small
              >
              <span>Issue</span>
            </v-chip>
            <v-chip
              class="chip-status-4 rounded px-4 py-1 mr-2 my-1 font-weight-bold text-wrap"
              small
            >
            <span>Processing</span>
            </v-chip>
          </span>
        </span>
      </span>
      </div>
    </template>
    <template v-slot:[`item.assigned_to`]="{ item }">
      <span v-if="item.accounts">{{(urlDecode(item.accounts.firstName || '')).toUpperCase()}} {{(urlDecode(item.accounts.lastName || '')).toUpperCase()}}</span>
    </template>
    <template v-slot:[`item.note`]="{ item }">
      <span v-if="item.issue == 'OTHER'">
        <span v-if="item.note"><i>{{mbStrimwidth(item.note.replaceAll("_", " "), 0, 50, "...")}}</i></span>
        <span v-else-if="item.issue"><i>OTHER ISSUES</i></span>
      </span>
      <span v-else-if="item.issue"><i>{{mbStrimwidth(item.issue.replaceAll("_", " "), 0, 50, "...")}}</i></span>
    </template>
        <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                outlined
                color="#166ecf"
                :to="'/' + $store.state.user.role + '/search/view/'+ item.token + '/'"
              >
              <v-icon class="mr-1">mdi-eye</v-icon>
              View
              </v-btn>
        </template>
    </v-data-table>
    </v-card>
    <v-row class="ma-2" align="center" justify="center">
    <v-col cols="auto" class="flex-items">
        <span class="px-2">Row Per Page: </span>
        <span>
          <v-select
            dense
            outlined
            hide-details
            :value="options.itemsPerPage"
            style="max-width: 90px"
            class="rounded-lg"
            @change="options.itemsPerPage = parseInt($event, 10)"
            :items="perPageChoices"
          >
          </v-select>
        </span>
    </v-col>
    <v-col cols="auto" class="mx-auto text-truncate" no-gutters>
    Showing {{ data.length > 0 ? ((paginationData.currentPage - 1) * options.itemsPerPage) + 1 : 0 }} to {{ data.length > 0 ? ((paginationData.currentPage - 1) * options.itemsPerPage) + data.length : 0 }} of {{ paginationData.count }} entries
    </v-col>
    <v-col cols="auto">
        <v-pagination
            v-model="options.page"
            class="rounded-lg"
            :total-visible="7"
            color="#38435a"
            :length="paginationData.lastPage">
        </v-pagination>
    </v-col>
      </v-row>
    </v-card>
</div>
</template>
<script>
  export default {
    props: {
      tab: null,
    },
    components: {
    },
    data: () => ({
      search: '',
      headers: [
          { text: 'REF#', value: 'token' },
          { text: 'PAYMENT DATE', value: 'payment_date' },
          { text: 'TYPE#', value: 'type'},
          { text: 'APPLICANT', value: 'applicant'},
          { text: 'STATUS', value: 'status', width: 400},
          { text: 'ASSIGNED', value: 'assigned_to'},
          { text: 'NOTE', value: 'note', width: 250 },
          { text: 'ACTIONS', value: 'actions' },
        ],
      data: [],
      perPageChoices: [
          {text:'5' , value: 5},
          {text:'10' , value: 10},
          {text:'20' , value: 20},
          {text:'50' , value: 50},
          {text:'100' , value: 100},
          {text:'250' , value: 250},
          {text:'500' , value: 500},
      ],
      options: {},
      paginationData: {},
      loading: false,
      formdata: [],
      work_dates_menu: false,
      fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
    },
    completeData: [],
    checkData: [],
    viewData: [],
    editData: [],
    }),
    watch: {
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      tab: {
        handler () {
        this.options.page = 1;
        this.initialize();
        },
        deep: true,
      },
      search: {
        handler () {
          setTimeout(() => {
            this.options.page = 1;
            this.initialize();
          }, 600);
        },
      },
    },
    mounted() {
    },
    methods: {
      initialize (){
        // console.log(this.options)
        this.loading = true;
        let page = this.options.page;
        let itemPerPage = this.options.itemsPerPage;
        let sortBy = this.options.sortBy.length > 0 ? this.options.sortBy[0] : '';
        let order = this.options.sortBy.length > 0 ? this.options.sortDesc[0] ? 'desc' : 'asc' : '';
        let search = this.search;
        this.axiosCall('/applications/getAllApplications?page=' + page + '&itemPerPage=' + itemPerPage + '&sortBy=' + sortBy + '&order=' + order + '&searchKey=' + search, 'GET').then((res) => {
              this.loading = false
              res.data.data.forEach(item => {
                item.data = this.isJsonString(item.data) ? JSON.parse(this.sanitizeJsonString(item.data)) : {}
                if(item.accounts == null){
                  item.accounts = {}
                }
              });
              this.data = res.data.data
              // console.log(this.data)
              this.paginationData = res.data.pagination;
          })
      },
      isJsonString(str) {
          try {
              JSON.parse(str);
          } catch (e) {
              return false;
          }
          return true;
      },
    }
  }
</script>
<style scoped>
.chip-status-1{
  background-color: #d7f7c2 !important;
  color: #2f8547 !important;
}
.chip-status-2{
  background-color: #cff5f6 !important;
  color: #1066c5 !important;
}
.chip-status-3{
  background-color: #ebeef1 !important;
  color: #616f7e !important;
}
.chip-status-4{
  background-color: #fcedb9 !important;
  color: #b02c00 !important;
}
.chip-status-5{
  background-color: #ffe7f2 !important;
  color: #c4093c !important;
}
.chip-status-6{
  background-color: #F0FFF8 !important;
  color: #18AB56 !important;
}
</style>