import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import "./assets/css/global.css"
import mixins from './mixins.js'
import store from './store';
import OtpInput from '@bachdgvn/vue-otp-input';
import VueJsonToCsv from 'vue-json-to-csv'
import HorizontalStepper from 'vue-stepper';
import { Translator } from 'vue-google-translate';
import Clipboard from 'v-clipboard'
const FadeAwayMessage = () => import("./components/Alerts/FadeAwayMessage");
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import DateRangePicker from "@gravitano/vue-date-range-picker";
Vue.config.productionTip = false
Vue.mixin(mixins)
Vue.use(Clipboard)
Vue.use(Toast, {});
Vue.use(DateRangePicker);
Vue.component("v-otp-input", OtpInput);
Vue.component("vue-json-to-csv", VueJsonToCsv);
Vue.component('fade-away-message-component', FadeAwayMessage);
Vue.component('horizontal-stepper', HorizontalStepper);
Vue.component('Translator', Translator);
Vue.component('date-picker', DatePicker);

// Global error handler
// Vue.config.errorHandler = function (err, vm, info) {
//     // Log the error message, stack trace, and info
//     const logMessage = `Error: ${err.message}\nInfo: ${info}\nStack: ${err.stack}`;
    
//     // Optionally send this log to a backend API
//     fetch(process.env.VUE_APP_SERVER_MAIN + '/client-logs.php', {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify({
//         message: err.message,
//         stack: err.stack,
//         info: info,
//         timestamp: new Date().toISOString(),
//       }),
//     });
  
//     // Also log the error in the browser's console
//     console.error(logMessage);
//   };
new Vue({
    vuetify,
    store,
    router,
    render: h => h(App)
}).$mount('#app')