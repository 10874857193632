<template>
<div>
  <div v-if="isLoading">
    <v-overlay
      :value="isLoading"
      color="white"
    >
      <v-progress-circular
        color="#38435a"
        indeterminate
        size="74"
      ></v-progress-circular>
    </v-overlay>
  </div>
  <div v-else class="pa-5">
  <div :key="app_key">
    <v-row>
      <v-col cols="12">
    <v-card v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" class="card-style rounded-1 mb-5" >
      <v-card-title> 
        <v-btn icon @click="$router.back()"><v-icon>mdi-chevron-left</v-icon></v-btn><h5>Application <strong>#{{$route.params.ref}}</strong> <small>[{{data.type}}]</small> — Management</h5>
      </v-card-title>
      <v-card-text>
        <v-divider></v-divider>
        <div class="ma-5 mb-15">
        <v-row>
          <v-col cols="12" align="right">
            <span v-if="data.paid == 0">
              <v-btn v-if="$store.state.user.role == 'admin'" color="#377dff" class="white--text pa-5 text-capitalized mr-2" depressed @click="paidApplication()"><v-icon class="mr-1">mdi-account-credit-card-outline</v-icon>Mark As Paid</v-btn>
              <v-btn color="red darken-2" class="white--text pa-5 text-capitalized mr-2" depressed @click="deleteApplication()"><v-icon>mdi-trash-can-outline</v-icon>Delete Application</v-btn>
            </span>
            <span v-else>
              <v-btn color="#377dff" class="white--text pa-5 text-capitalized mr-2" depressed @click="makeDuplicate()"><v-icon>mdi-content-copy</v-icon>Make Duplicate</v-btn>
            </span>
            <v-btn color="#ed4c82" class="white--text pa-5 text-capitalized" v-if="data.voided == 0" depressed @click="voidApplication()"><v-icon>mdi-file-document-remove</v-icon>Void Application</v-btn>
            <v-btn color="#00A34E" class="white--text pa-5 text-capitalized" v-if="data.voided == 1" depressed @click="unvoidApplication()"><v-icon>mdi-file-undo</v-icon>Unvoid Application</v-btn>
          </v-col>
        </v-row>
        <v-row class="flex-items">
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
          <v-col cols="auto" class="mx-auto">
            <span class="view-main-title">Management Area</span>
          </v-col>
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
        </v-row>
        <v-row v-if="data.voided == 1">
          <v-col cols="12">
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Void Status</v-col> <v-col cols="12" sm="6" class="py-1"><v-chip color="error" class="rounded white--text px-4 py-1 font-weight-bold text-wrap" small>Voided</v-chip></v-col>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Void Reason</v-col> <v-col cols="12" sm="6" class="py-1"><strong>{{data.voidReason}}</strong></v-col>
            </v-row>
          </v-col>
          <v-col><v-divider color="#cfd4d7"></v-divider></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Issue / Problem</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="issue == false"><v-chip class="rounded px-4 py-1 font-weight-bold text-wrap white--text" :color="data.issue == null ? '#00a34e' : '#c4093c'" small >{{ data.issue ? $store.state.application_issues.find(item => item.id == data.issue).desc : 'OK' }} </v-chip> <v-btn text color="btnText" small @click="issue = true">Update</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="issue_id"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    :items="$store.state.application_issues"
                    item-value='id'
                    item-text='desc'
                    outlined
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="issue = false"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="issueFunc(issue_id)"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" v-if="data.issue == 'OTHER'">
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Issue Note</v-col> <v-col cols="12" sm="6" class="py-1">
                <div v-if="issue_note == false"><strong>{{data.note}}</strong><v-btn text color="btnText" small @click="issue_note = true">Update</v-btn></div>
                <v-row v-else no-gutters>
                    <v-col cols="12" class="pb-1">
                      <v-text-field
                        v-model="note"
                        color="#677788"
                        :rules="[formRules.required]"
                        height="45"
                        width="100%"
                        outlined
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="issue_note = false"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                    <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="issueNoteFunc(note)"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                  </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="data.issue == 'PREVIOUS_APPLICATION_TRAVEL_NOT_AUTHORIZED'">
          <!-- <v-col cols="12"> -->
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Suggested Admin Action</v-col> <v-col cols="12" sm="6" class="py-1">
                <v-btn v-if="data.decisionSent == 0 && data.decisionResult == null" dark outlined color="#c4093c" style="background-color: #ffe7f2;" depressed small @click="markDecisionAsRejected()"><v-icon small>mdi-close</v-icon>Mark as Rejected</v-btn>
                <v-chip v-else-if="data.decisionSent == 1 && data.decisionResult == 'rejected'" color="#ffe7f2" style="color: #c4093c;" class="rounded px-4 py-1 font-weight-bold text-wrap" small><v-icon class="mr-1" small color="#c4093c">mdi-close</v-icon>Rejected</v-chip>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Applicant Language</v-col> <v-col cols="12" sm="6" class="py-1"><strong>{{setLanguage(data.lang || 'en')}}</strong></v-col>
          <v-col cols="12" v-if="data.ticketCreated == 1 || data.issue != null">
            <v-row>
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Ticket Created</v-col> <v-col cols="12" sm="6" class="py-1">
              <div v-if="ticket_create == false"><v-chip class="rounded px-4 py-1 font-weight-bold text-wrap white--text" small :color="data.ticketCreated == 1 ? '#00a34e' : '#c4093c'">{{data.ticketCreated == 1 ? 'YES' : 'NO'}}</v-chip><v-btn text color="btnText" small @click="ticket_create = true">Update</v-btn></div>
              <v-row v-else no-gutters>
                  <v-col cols="12" class="pb-1">
                    <v-radio-group
                      v-model="ticket_create_bool"
                      :rules="[formRules.required3]"
                      row
                      class="mt-0"
                      >
                      <v-radio
                          label="No"
                          :value="0"
                          color="#00a34e"
                      ></v-radio>
                      <v-radio
                          label="Yes"
                          :value="1"
                          color="#00a34e"
                      ></v-radio>
                  </v-radio-group>
                  </v-col>
                  <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="ticket_create = false"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                  <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="ticketCreateFunc(ticket_create_bool)"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                </v-row>
            </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Ticket Preview</v-col> <v-col cols="12" sm="6" class="py-1">
            <div v-if="ticket_preview == false"><a :href="data.ticketUrl" target="_blank" class="text-decoration-none">{{data.ticketUrl}}</a> <v-btn text color="btnText" small @click="ticket_preview = true">Update</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-text-field
                    v-model="ticket_preview_url"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="ticket_preview = false"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="ticketPreviewFunc(ticket_preview_url)"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
        </v-row>
        </div>
      </v-card-text>
    </v-card>
    <v-card v-else-if="$store.state.user.role == 'agent'" class="card-style rounded-1 mb-5" >
      <v-card-title> 
        <v-btn icon @click="$router.back()"><v-icon>mdi-chevron-left</v-icon></v-btn><h5>Application <strong>#{{$route.params.ref}}</strong> <small>[{{data.type}}]</small> — Management</h5>
      </v-card-title>
      <v-card-text>
        <v-divider></v-divider>
        <div class="ma-5 mb-15">
        <v-row class="flex-items">
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
          <v-col cols="auto" class="mx-auto">
            <span class="view-main-title">Management Area</span>
          </v-col>
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
        </v-row>
        <v-row v-if="data.voided == 1">
          <v-col cols="12">
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Void Status</v-col> <v-col cols="12" sm="6" class="py-1"><v-chip color="error" class="rounded white--text px-4 py-1 font-weight-bold text-wrap" small>Voided</v-chip></v-col>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Void Reason</v-col> <v-col cols="12" sm="6" class="py-1"><strong>{{data.voidReason}}</strong></v-col>
            </v-row>
          </v-col>
          <v-col><v-divider color="#cfd4d7"></v-divider></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Issue / Problem</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
            <div v-if="issue == false"><v-chip class="rounded px-4 py-1 font-weight-bold text-wrap white--text" :color="data.issue == null ? '#00a34e' : '#c4093c'" small >{{ data.issue ? $store.state.application_issues.find(item => item.id == data.issue).desc : 'OK' }} </v-chip> <v-btn text color="btnText" small @click="issue = true">Update</v-btn></div>
            <v-row v-else no-gutters>
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="issue_id"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    :items="$store.state.application_issues"
                    item-value='id'
                    item-text='desc'
                    outlined
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="issue = false"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="issueFunc(issue_id)"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
              </v-row>
          </v-col>
          <v-col cols="12" v-if="data.issue == 'OTHER'">
            <v-row>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Issue Note</v-col> <v-col cols="12" sm="6" class="py-1">
                <div v-if="issue_note == false"><strong>{{data.note}}</strong><v-btn text color="btnText" small @click="issue_note = true">Update</v-btn></div>
                <v-row v-else no-gutters>
                    <v-col cols="12" class="pb-1">
                      <v-text-field
                        v-model="note"
                        color="#677788"
                        :rules="[formRules.required]"
                        height="45"
                        width="100%"
                        outlined
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="issue_note = false"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                    <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="issueNoteFunc(note)"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Save</v-btn></v-col>
                  </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Applicant Language</v-col> <v-col cols="12" sm="6" class="py-1"><strong>{{setLanguage(data.lang || 'en')}}</strong></v-col>
        </v-row>
        </div>
      </v-card-text>
    </v-card>

    <v-card class="card-style rounded-1">
      <v-card-title>
        <v-btn icon @click="$router.back()"><v-icon>mdi-chevron-left</v-icon></v-btn><h5>Application <strong>#{{$route.params.ref}}</strong>  <small>[{{data.type}}]</small> — Preview</h5>
      </v-card-title>
      <v-card-text>
        <v-divider></v-divider>
        <div class="ma-5">
        <v-row class="flex-items">
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
          <v-col cols="auto" class="mx-auto">
            <span class="view-main-title">System Information</span>
          </v-col>
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Application Reference Number</v-col> <v-col v-if="data.token" cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.token" ><strong>{{data.token}}</strong></v-col><v-col v-else cols="12" sm="6"></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Government Website</v-col>
          <v-col v-if="data.type" cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="getGovWebsite(data.type)" ><strong>{{getGovWebsite(data.type)}}</strong></v-col>
          <v-col v-else cols="12" sm="6"></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Applicant Email</v-col> <v-col v-if="data.email" cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.email"><strong>{{data.email}}</strong></v-col><v-col v-else cols="12" sm="6"></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Assigned To</v-col> <v-col cols="12" sm="6" class="py-1">
            <div v-if="!reassign && !release" class="flex-items">
            <span v-if="data.assignedTo && data.accounts">
              <div v-if="data.accounts.role == 'agent'" class="Box-sc-kv6pi1-0 kJJUso VipBadge" style="margin-right: 10px;vertical-align: top;"><div class="Box-sc-kv6pi1-0 iQKWQz"><img alt="star_with_vip" src="https://portal.vipglobal.us.com/assets/svg/star.svg" style="height: 14px;margin-right: -9px;"></div><div class="Box-sc-kv6pi1-0 kednUW VipBadge__GradientContainer" style="display: flex; align-items: center;"><span class="Spanstyled__SpanStyled-sc-16tp9kb-0 dIscLj kite-js-Span VipBadge__InfoText VipBadge__InfoText--s">Agent</span><div class="Box-sc-kv6pi1-0 kUkAkB VipBadge__Line VipBadge__Line--vipBronze"></div><div class="Box-sc-kv6pi1-0 cfMoGs VipBadge__Line"></div></div></div>
              <div v-else-if="data.accounts.role == 'executive_supervisor'" class="Box-sc-kv6pi1-0 kJJUso VipBadge" style="margin-right: 10px;vertical-align: top;"><div class="Box-sc-kv6pi1-0 iQKWQz"><img alt="star_with_vip" src="https://portal.vipglobal.us.com/assets/svg/star.svg" style="height: 14px;margin-right: -9px;"></div><div class="Box-sc-kv6pi1-0 kednUWGold VipBadge__GradientContainer" style="display: flex; align-items: center;"><span class="Spanstyled__SpanStyled-sc-16tp9kb-0 dIscLj kite-js-Span VipBadge__InfoText VipBadge__InfoText--s text-no-wrap">Executive Supervisor</span><div class="Box-sc-kv6pi1-0 kUkAkB VipBadge__Line  VipBadge__Line--vipGold"></div><div class="Box-sc-kv6pi1-0 cfMoGs VipBadge__Line"></div></div></div>
              <div v-else-if="data.accounts.role == 'admin'" class="Box-sc-kv6pi1-0 kJJUso VipBadge" style="margin-right: 10px;vertical-align: top;"><div class="Box-sc-kv6pi1-0 iQKWQz"><img alt="star_with_vip" src="https://portal.vipglobal.us.com/assets/svg/star.svg" style="height: 14px;margin-right: -9px;"></div><div class="Box-sc-kv6pi1-0 kednUWGold VipBadge__GradientContainer" style="display: flex; align-items: center;"><span class="Spanstyled__SpanStyled-sc-16tp9kb-0 dIscLj kite-js-Span VipBadge__InfoText VipBadge__InfoText--s">Admin</span><div class="Box-sc-kv6pi1-0 kUkAkB VipBadge__Line  VipBadge__Line--vipGold"></div><div class="Box-sc-kv6pi1-0 cfMoGs VipBadge__Line"></div></div></div>
              
            <strong class="text-no-wrap">{{data.accounts.firstName + ' ' + data.accounts.lastName}}</strong>
            </span>
            <span v-if="$store.state.user.role == 'executive_supervisor' || $store.state.user.role == 'admin'"><strong v-if="data.assignedTo == null || data.assignedTo == ''" style="color:#677788;">NOT YET ASSIGNED</strong><v-btn text color="btnText" small @click="reassign = true">{{data.assignedTo ? 'Re-Assign' : 'Assign'}}</v-btn><span v-if="data.assignedTo && data.accounts">or<v-btn text color="primary" small @click="release = true">Release</v-btn></span></span>
            </div>
            <div v-else>
              <v-row no-gutters v-if="reassign == true">
                <v-col cols="12" class="pb-1">
                  <v-autocomplete
                    v-model="reassign_id"
                    color="#677788"
                    :rules="[formRules.required]"
                    height="45"
                    width="100%"
                    :items="accounts"
                    item-value="id"
                    item-text="name"
                    outlined
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="reassign = false"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="reassignFunc(reassign_id)"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Re-assign</v-btn></v-col>
              </v-row>
              <v-row no-gutters v-else-if="release == true">
                <v-col cols="12" class="pb-1">
                  <h3>Release Application #{{data.token}} to pool of available applications?</h3>
                </v-col>
                <v-col cols="6" class="py-1"><v-btn color="#71869d" dark depressed @click="release = false"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Cancel</v-btn></v-col>
                <v-col cols="6" class="py-1 text-end"><v-btn color="#00a34e" dark depressed @click="releaseApplication()"><v-icon class="mr-1">mdi-check-circle-outline</v-icon>Release</v-btn></v-col>
              </v-row>
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">System Email</v-col> <v-col v-if="data.systemEmail" cols="12" sm="6" class="py-1"><span class="clipboard-style py-1" v-clipboard="data.systemEmail"><strong>{{data.systemEmail}}</strong></span>
          <v-btn v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'" text color="btnText" small @click="regenerateSystemEmail()">Re-Generate New System Email</v-btn>
          </v-col><v-col v-else cols="12" sm="6"></v-col>
          <v-col cols="12" v-if="data.type != 'K-ETA'">
            <v-row v-if="$store.state.user.role == 'admin'">
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Email Verification Code</v-col> <v-col v-if="data.applicationEmailCode" cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.applicationEmailCode"><strong>{{data.applicationEmailCode}}</strong></v-col><v-col v-else cols="12" sm="6" class="py-1" style="color:#677788;"><strong>N/A</strong></v-col>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Application Number</v-col> <v-col v-if="data.applicationNumber" cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.applicationNumber"><strong>{{data.applicationNumber}}</strong></v-col><v-col v-else cols="12" sm="6" class="py-1" style="color:#677788;"><strong>N/A</strong></v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Email Verification Code</v-col> <v-col v-if="data.applicationEmailCode" cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.applicationEmailCode"><strong>{{data.applicationEmailCode}}</strong></v-col><v-col v-else cols="12" sm="6" class="py-1" style="color:#677788;"><strong>AWAITING TO RECEIVE...</strong></v-col>
              <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Application Number</v-col> <v-col v-if="data.applicationNumber" cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.applicationNumber"><strong>{{data.applicationNumber}}</strong></v-col><v-col v-else cols="12" sm="6" class="py-1" style="color:#677788;"><strong>AWAITING TO RECEIVE...</strong></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Payment Card</v-col> <v-col cols="12" sm="6" class="py-1"><v-btn text color="btnText" small @click="generateNewCard(true)">Generate New Payment Card</v-btn></v-col>
          <v-col cols="12" sm="6" class="text-sm-end view-title py-1">
            <span v-if="data.type == 'K-ETA'">To be used only for <br />K-ETA application fee to the<br />Government of Republic of Korea.</span>
            <span v-else-if="data.type == 'USA-ESTA'">To be used only for <br />ESTA application fee to<br />U.S. Customs & Border Protection.</span>
            </v-col> <v-col cols="12" sm="6" class="py-1">
            <div class="cc-card" v-if="data.cardNumber">
              <div class="cc-card-content">
              <svg id="logo-visa" enable-background="new 0 0 50 70" height="70px" version="1.1" viewBox="0 0 50 50" width="70px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g><polygon clip-rule="evenodd" fill="#f4f5f9" fill-rule="evenodd" points="17.197,32.598 19.711,17.592 23.733,17.592     21.214,32.598   "></polygon><path clip-rule="evenodd" d="M35.768,17.967c-0.797-0.287-2.053-0.621-3.596-0.621    c-3.977,0-6.752,2.029-6.776,4.945c-0.023,2.154,1.987,3.358,3.507,4.08c1.568,0.738,2.096,1.201,2.076,1.861    c0,1.018-1.238,1.471-2.395,1.471c-1.604,0-2.455-0.232-3.773-0.787l-0.53-0.248l-0.547,3.348    c0.929,0.441,2.659,0.789,4.462,0.811c4.217,0,6.943-2.012,6.979-5.135c0.025-1.692-1.053-2.999-3.369-4.071    c-1.393-0.685-2.246-1.134-2.246-1.844c0-0.645,0.723-1.306,2.295-1.306c1.314-0.024,2.268,0.271,3.002,0.58l0.365,0.167    L35.768,17.967z" fill="#f4f5f9" fill-rule="evenodd"></path><path clip-rule="evenodd" d="M46.055,17.616h-3.102c-0.955,0-1.688,0.272-2.117,1.24    l-5.941,13.767h4.201c0,0,0.688-1.869,0.852-2.262c0.469,0,4.547,0,5.133,0c0.123,0.518,0.49,2.262,0.49,2.262h3.711    L46.055,17.616 M41.1,27.277c0.328-0.842,1.609-4.175,1.609-4.175c-0.041,0.043,0.328-0.871,0.529-1.43l0.256,1.281    c0,0,0.773,3.582,0.938,4.324H41.1z" fill="#f4f5f9" fill-rule="evenodd"></path><path clip-rule="evenodd" d="M13.843,17.616L9.905,27.842l-0.404-2.076    c-0.948-2.467-2.836-4.634-5.53-6.163l3.564,12.995h4.243l6.312-14.982H13.843z" fill="#f4f5f9" fill-rule="evenodd"></path><path clip-rule="evenodd" d="M7.232,17.174H0.755l-0.037,0.333    c5.014,1.242,8.358,4.237,9.742,7.841l-1.42-6.884C8.798,17.507,8.105,17.223,7.232,17.174L7.232,17.174z" fill="#f4f5f9" fill-rule="evenodd"></path></g></g></svg>
              <h5>Card Number</h5>

              <h6 id="label-cardnumber">
              <div class="clipboard-style" v-clipboard="data.cardNumber">
              <span class="label-cardnumber">{{data.cardNumber.substring(0, 4)}}</span>
              <span class="label-cardnumber">{{data.cardNumber.substring(4, 8)}}</span>
              <span class="label-cardnumber">{{data.cardNumber.substring(8, 12)}}</span>
              <span class="label-cardnumber">{{data.cardNumber.substring(12, 16)}}</span>
              </div>
              </h6>                                    
              <h5>Expiration<span>CVV</span></h5>
              <h6 id="label-cardexpiration"><span style="margin-left: -10px;padding: 5px 10px;" v-clipboard="data.expiryMonth">{{data.expiryMonth}}</span> / <span style="padding: 5px 10px;" v-clipboard="data.expiryYear">{{data.expiryYear}}</span><span style="margin-left: 51px;padding: 5px 10px;" class="cvv" v-clipboard="data.cvv">{{data.cvv}}</span></h6>
              </div>
              <div class="cc-wave"></div>
            </div>
            <div v-else style="color:#677788;">
              <strong>NOT YET ISSUED</strong>
            </div>
          </v-col>
          <v-col cols="12">
          <v-row v-if="data.cardNumber">
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Cardholder Name</v-col> <v-col v-if="data.data" cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="sanitizeString(urlDecode(data.data.first_given_names)) + ' ' + sanitizeString(urlDecode(data.data.family_names))"><strong>{{sanitizeString(urlDecode(data.data.first_given_names)) + " " + sanitizeString(urlDecode(data.data.family_names || ''))}}</strong></v-col><v-col v-else cols="12" sm="6"></v-col>
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Billing Address — Address Line 1</v-col> <v-col v-if="data.billingAddressLine_1" cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.billingAddressLine_1"><strong>{{data.billingAddressLine_1}}</strong></v-col><v-col v-else cols="12" sm="6"></v-col>
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Billing Address — Address Line 2</v-col> <v-col v-if="data.billingAddressLine_2" cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.billingAddressLine_2"><strong>{{data.billingAddressLine_2}}</strong></v-col><v-col v-else cols="12" sm="6"></v-col>
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Billing Address — City</v-col> <v-col v-if="data.billingCity" cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.billingCity"><strong>{{data.billingCity}}</strong></v-col><v-col v-else cols="12" sm="6"></v-col>
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Billing Address — State</v-col> <v-col v-if="data.billingState" cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.billingState"><strong>{{data.billingState}}</strong></v-col><v-col v-else cols="12" sm="6"></v-col>
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Billing Address — ZIP</v-col> <v-col v-if="data.billingZip" cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.billingZip"><strong>{{data.billingZip}}</strong></v-col><v-col v-else cols="12" sm="6"></v-col>
            <v-col cols="12" sm="6" class="text-sm-end view-title py-1">Billing Address — Country</v-col> <v-col v-if="data.billingCountry" cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.billingCountry"><strong>{{data.billingCountry}}</strong></v-col><v-col v-else cols="12" sm="6"></v-col>
          </v-row>
          </v-col>
        </v-row>
        <div v-if="data.type == 'USA-ESTA' || data.type == 'UK-EVW' || data.type == 'KH-EVISA' || data.type == 'VN-EVISA'">
        <v-row class="flex-items mt-4">
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
          <v-col cols="auto" class="mx-auto">
            <span class="text-subtitle1 view-main-title" id="applicant-passport-div">Applicant Passport</span>
          </v-col>
          <v-col><v-divider color="#909fa9"></v-divider></v-col>
        </v-row>
              <span v-if="data.passportValidationUrl">
                <span v-if="data.passportValidationData">
                  <span v-if="data.passportValidationData.verification && data.passportValidationData.verification.passed">
                  <v-row>
                    <v-col cols="12" sm="6" class="text-sm-end view-title py-1">IDAnalyzer® Verification Link</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                      <span class="clipboard-style" v-clipboard="data.passportValidationUrl"><strong>{{data.passportValidationUrl}}</strong></span>
                      <v-btn text color="btnText" small @click="generateIDAnalyzer()">Re-Generate IDAnalyzer® Verification Link</v-btn>
                    </v-col>
                    <v-col cols="12" sm="6" class="text-sm-end view-title py-1">IDAnalyzer® Verification Status</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                      <v-chip class="chip-status-1 rounded px-4 py-1 font-weight-bold text-wrap" small><v-icon color="#1d7327" small class="mr-1">mdi-check</v-icon>Success</v-chip>
                      <v-btn v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'"  text color="#c4093c" small @click="resubmitIDAnalyzer()">PASSPORT IMAGE UNACCEPTABLE</v-btn>
                    </v-col>
                    <v-col cols="12" class="py-1 text-sm-center">
                        <div><img class="image-fluid rounded-lg passport-img" :src="data.passportValidationData.documentimage[0].url"/></div>
                        <v-btn color="#f5ca99" class="px-15 py-4" depressed @click="downloadPhoto(data.passportValidationData.documentimage[0].url, data.data.first_given_names, data.data.family_names, 'PASSPORT')"><v-icon>mdi-download</v-icon> Download Passport</v-btn>
                      </v-col>
                    </v-row>
                  </span>
                  <span v-else>
                    <v-row>
                      <v-col cols="12" sm="6" class="text-sm-end view-title py-1">IDAnalyzer® Verification Link</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                        <span class="clipboard-style" v-clipboard="data.passportValidationUrl"><strong>{{data.passportValidationUrl}}</strong></span>
                        <v-btn text color="btnText" small @click="generateIDAnalyzer()">Re-Generate IDAnalyzer® Verification Link</v-btn>
                    </v-col>
                      <v-col cols="12" sm="6" class="text-sm-end view-title py-1">IDAnalyzer® Verification Status</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                        <v-chip color="#ffe7f2" style="color: #c4093c;" class="rounded px-4 py-1 font-weight-bold text-wrap" small><v-icon class="mr-1" small color="#c4093c">mdi-close</v-icon>Failed</v-chip>
                        <v-btn v-if="$store.state.user.role == 'admin' || $store.state.user.role == 'executive_supervisor'"  text color="#c4093c" small @click="resubmitIDAnalyzer()">PASSPORT IMAGE UNACCEPTABLE</v-btn>
                      </v-col>
                    </v-row>
                    <v-row v-if="data.passportValidationData.failreason">
                      <v-col cols="12" sm="6" class="text-sm-end view-title py-1">IDAnalyzer® Failure Reason</v-col> <v-col cols="12" sm="6" class="py-1">
                        <strong>{{data.passportValidationData.failreason}}</strong>
                      </v-col>
                    </v-row>
                    <v-row v-if="data.passportValidationData.documentimage && data.passportValidationData.documentimage.length > 0 &&data.passportValidationData.documentimage[0].url">
                      <v-col cols="12" class="py-1 text-sm-center">
                        <div><img class="image-fluid rounded-lg passport-img-failed" :src="data.passportValidationData.documentimage[0].url"/></div>
                        <v-btn color="#f5ca99" class="px-15 py-4" depressed @click="downloadPhoto(data.passportValidationData.documentimage[0].url, data.data.first_given_names, data.data.family_names, 'PASSPORT')"><v-icon>mdi-download</v-icon> Download Passport</v-btn>
                      </v-col>
                    </v-row>
                  </span>
                </span>
                <span v-else>
                  <v-row>
                    <v-col cols="12" sm="6" class="text-sm-end view-title py-1">IDAnalyzer® Verification Link</v-col> <v-col cols="12" sm="6" class="py-1 clipboard-style" v-clipboard="data.passportValidationUrl"><a href="javascript:void(0);" class="text-decoration-none">{{data.passportValidationUrl}}</a></v-col>
                    <v-col cols="12" sm="6" class="text-sm-end view-title py-1">IDAnalyzer® Verification Status</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                        <strong>NOT YET VERIFIED</strong>
                        <v-btn text color="btnText" small @click="generateIDAnalyzer()">Re-Generate IDAnalyzer® Verification Link</v-btn>
                      </v-col>
                  </v-row>
                </span>
              </span>
              <span v-else>
                <v-row><v-col cols="12" sm="6" class="text-sm-end view-title py-1">IDAnalyzer® Verification Link</v-col> <v-col cols="12" sm="6" class="py-1 flex-items">
                    <!-- <strong>NOT YET GENERATED</strong> -->
                    <v-btn text color="btnText" small @click="generateIDAnalyzer()">Generate IDAnalyzer® Verification Link</v-btn>
                  </v-col>
                </v-row>
              </span>
          
        </div>
        <div id="applicant-information-div">
          <Korea v-if="data.type == 'K-ETA'" :data="data.data" :systemEmail="data.systemEmail" />
          <UnitedStatesOfAmerica v-if="data.type == 'USA-ESTA'" :data="data.data" :passportValidationData="data.passportValidationData" :passportDataMatch="data.passportDataMatch" :passportDataComparison="data.passportDataComparison" :systemEmail="data.systemEmail" />
          <UnitedKingdom v-if="data.type == 'UK-EVW'" :data="data.data" :docupass="data.passportValidationUrl ? true : false" :systemEmail="data.systemEmail" />
          <Cambodia v-if="data.type == 'KH-EVISA'" :data="data.data" />
          <Vietnam v-if="data.type == 'VN-EVISA'" :data="data.data" />
          <NewZealand v-if="data.type == 'NZ-ETA'" :data="data.data" />
          <Canada v-if="data.type == 'CA-ETA'" :data="data.data" />
        </div>
        </div>
      <v-divider v-if="data.submitted == 0 && (data.applicationNumber || data.type == 'K-ETA' || data.type == 'UK-EVW')"></v-divider>
      </v-card-text>
      <v-card-actions class="pa-5" v-if="data.submitted == 0 && (data.applicationNumber || data.type == 'K-ETA' || data.type == 'UK-EVW')">
        <v-spacer></v-spacer>
        <v-btn color="#00A34E" class="white--text pa-5 text-capitalized" depressed @click="markAsSubmitted()">Mark Application as Submitted</v-btn>
      </v-card-actions>
    </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogConfirmation"
      width="520"
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          <strong v-if="action == 'generate_id_analyzer'">Generate ID Analyzer for Application #{{data.token}}</strong>
          <strong v-else-if="action == 'resubmit_id_analyzer'">Resubmit ID Analyzer for Application #{{data.token}}</strong>
          <strong v-else-if="action == 'decision_rejected'">Decision for Application #{{data.token}}</strong>
          <strong v-else>{{capitalizeFirstLetter(action)}} Application #{{data.token}}</strong>
        </v-card-title>

        <v-card-text>
          <v-form ref="dialogForm">
            <div v-if="action == 'void'" class="subtitle-1">
              Are you sure you want to <strong>VOID</strong> Application <strong>#{{data.token}}</strong>? Please, provide reason below:
              <div class="mt-1">
                <v-textarea
                  v-model="void_reason"
                  placeholder="Void Reason"
                  color="#677788"
                  :rules="[formRules.required]"
                  outlined
                  rows="2"
                  hide-details="auto"
                ></v-textarea>
              </div>
            </div>
            <div v-else-if="action == 'unvoid'">
              Are you sure you want to <strong>UNVOID</strong> Application <strong>#{{data.token}}</strong> ?<br />
            </div>
            <div v-if="action == 'duplicate'">
              Are you sure you want to <strong>MAKE A DUPLICATE</strong> of Application <strong>#{{data.token}}</strong> ?<br />
            </div>
            <div v-if="action == 'delete'">
              Are you sure you want to <strong>DELETE</strong> Application <strong>#{{data.token}}</strong> ?<br />
            </div>
            <div v-if="action == 'paid'">
              Are you sure you want to mark the Application <strong>#{{data.token}}</strong> as <strong>PAID</strong>?<br />
            </div>
            <div v-if="action == 'generate_id_analyzer'">
              Are you sure you want to generate a new <strong>ID analyzer</strong> for the Application <strong>#{{data.token}}</strong>?<br />
            </div>
            <div v-if="action == 'resubmit_id_analyzer'">
              Are you sure you want to request for resubmission of <strong>Passport Photo</strong> for the Application <strong>#{{data.token}}</strong>?<br />
            </div>
            <div v-if="action == 'decision_rejected'">
              Are you sure you want to mark the application as <strong>REJECTED</strong>?<br />
            </div>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            outlined
            class="mr-2"
            depressed
            @click="dialogConfirmation = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="#00A34E"
            class="white--text"
            depressed
            @click="confirmUnvoidApplication()"
            v-if="action == 'unvoid'"
          >
            Yes, Unvoid
          </v-btn>
          <v-btn
            color="pink"
            class="white--text"
            depressed
            @click="confirmVoidApplication()"
            v-else-if="action == 'void'"
          >
            Yes, Void
          </v-btn>
          <v-btn
            color="#377dff"
            class="white--text"
            depressed
            @click="confirmDuplicateApplication()"
            v-else-if="action == 'duplicate'"
          >
            Yes, MAKE DUPLICATE
          </v-btn>
          <v-btn
            color="red darken-2"
            class="white--text"
            depressed
            @click="confirmDeleteApplication()"
            v-else-if="action == 'delete'"
          >
            Yes, Delete
          </v-btn>
          <v-btn
            color="#377dff"
            class="white--text"
            depressed
            @click="confirmPaidApplication()"
            v-else-if="action == 'paid'"
          >
            Yes, Mark As Paid
          </v-btn>
          <v-btn
            color="#c4093c"
            class="white--text"
            depressed
            @click="confirmResubmitIDAnalyzer()"
            v-else-if="action == 'resubmit_id_analyzer'"
          >
            Yes, Confirm
          </v-btn>
          <v-btn
            color="#377dff"
            class="white--text"
            depressed
            @click="confirmGenerateIDAnalyzer()"
            v-else-if="action == 'generate_id_analyzer'"
          >
            Yes, Generate
          </v-btn>
          <v-btn
            color="#b02c00"
            class="white--text"
            depressed
            @click="confirmMarkDecisionAsRejected()"
            v-else-if="action == 'decision_rejected'"
          >
            Yes, Rejected
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  </div>
</div>
</template>

<script>
import Korea from '../Authorizations/Korea.vue'
import UnitedStatesOfAmerica from '../Authorizations/UnitedStatesOfAmerica.vue'
import UnitedKingdom from '../Authorizations/UnitedKingdom.vue'
import Cambodia from '../Authorizations/Cambodia.vue'
import Vietnam from '../Authorizations/Vietnam.vue'
import NewZealand from '../Authorizations/NewZealand.vue'
import Canada from '../Authorizations/Canada.vue'
  export default {
    components: {
      Korea,
      UnitedStatesOfAmerica,
      UnitedKingdom,
      Cambodia,
      Vietnam,
      NewZealand,
      Canada
    },
    data: () => ({
      data: {},
      isLoading: false,
      issue: false,
      ticket_create: false,
      ticket_preview: false,
      reassign: false,
      release: false,
      passval: false,
      issue_id: null,
      issue_note: false,
      note: null,
      ticket_create_bool: null,
      ticket_preview_url: null,
      reassign_id: null,
      release_id: null,
      passval_url: null,
      accounts: [],
      dialogConfirmation: false,
      void_reason: null,
      action: '',
      application_data_listener: null,
      app_key: 0,
    }),
    watch: {
      data: {
        handler(){
            this.app_key++;
        },
        deep: true
      }
    },
    methods: {
        issueFunc(issue_id){
          if(issue_id == 'OK_NO_ISSUES'){
            issue_id = null
          }
          let data = {
          token: this.data.token,
          issue: issue_id,
          };
        this.axiosCall("/applications/issue", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.issue = false
            this.initialize();
            this.showToast(res.data.msg, 'success')
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
        });
        },
        issueNoteFunc(issue_note){
          let data = {
          token: this.data.token,
          note: issue_note,
          };
        this.axiosCall("/applications/issueNote", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.issue_note = false
            this.initialize();
            this.showToast(res.data.msg, 'success')
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
        });
        },
        ticketCreateFunc(bool){
          let data = {
          token: this.data.token,
          ticketCreated: bool,
          };
        this.axiosCall("/applications/ticketCreate", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.ticket_create = false
            this.initialize();
            this.showToast(res.data.msg, 'success')
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
        });
        },
        ticketPreviewFunc(url){
          let data = {
          token: this.data.token,
          ticketUrl: url,
          };
        this.axiosCall("/applications/ticketPreview", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.ticket_preview = false
            this.initialize();
            this.showToast(res.data.msg, 'success')
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
        });
        },
        reassignFunc(account_id){
          let data = {
          token: this.data.token,
          reassign_to: parseInt(account_id),
          };
        this.axiosCall("/applications/reassign", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.reassign = false
            this.initialize();
            this.showToast(res.data.msg, 'success')
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
        });
        },
        initialize(){
          this.isLoading = true;
          let data = {
          token: this.$route.params.ref,
          };
        this.axiosCall("/applications/getByToken", "POST", data).then((res) => {
          if (res.status == 201) {
            this.isLoading = false;
            let temp_data = res.data
            let user = this.$store.state.user;
            if(user.role == 'agent' && (temp_data.assignedTo == null || (temp_data.accounts && temp_data.accounts.id != user.id))){
              this.$router.push('/agent/applications')
            }
            temp_data.data = JSON.parse(this.sanitizeJsonString(temp_data.data))
            temp_data.passportValidationData = JSON.parse(temp_data.passportValidationData)
            temp_data.passportDataComparison = JSON.parse(temp_data.passportDataComparison)
            this.issue_id = res.data.issue,
            this.note = res.data.note,
            this.ticket_create_bool = res.data.ticketCreated,
            this.ticket_preview_url = res.data.ticketUrl,
            this.reassign_id = res.data.assignedTo
            this.passval_url = res.data.passportValidationUrl
            this.data = temp_data;
            console.log(this.data)

            if(temp_data.assignedTo && (temp_data.cardNumber == "" || temp_data.cardNumber == null) && temp_data.cardStatus != "ACTIVE" && (temp_data.cardId == "" || temp_data.cardId == null)){
              this.generateNewCard(false);
            }
            this.initiateRealTimeData()
          }
        });
        },
        getAccounts(){
        this.axiosCall("/accounts/getUsers", "GET").then((res) => {
          this.isLoading = false;
          if (res.status == 200) {
            this.accounts = res.data;
          }
        });
        },
        setLanguage(lang){
          const languageNames = new Intl.DisplayNames(['en'], {
            type: 'language'
          });
          return languageNames.of(lang)
        },
        getCountry(code) {
          let res = 'None'
          if(code){
          this.$store.state.countries.forEach(item => {
            if(item.code.toLowerCase() == code.toLowerCase() || item.code_3.toLowerCase() == code.toLowerCase()){
              res = item.country
            }
          });
          }
          return res;
        },
        releaseApplication(){
          let data = {
          token: this.data.token
          };
        this.axiosCall("/applications/release", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.release = false
            this.initialize();
            this.showToast(res.data.msg, 'success')
            // this.$route.push('/' + this.$store.state.user.role + '/applications-all')
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
        });
        },
        generateNewCard(notify){
          let data = {
          token: this.data.token,
          };
        this.axiosCall("/cards-bank/generate", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.initialize();
            if(notify){
            this.showToast(res.data.msg, 'success')
            }
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
        });
        },
        regenerateSystemEmail(){
          let data = {
          token: this.data.token,
          };
        this.axiosCall("/applications/regenerate-system-email", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.initialize();
            this.showToast(res.data.msg, 'success')
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
        });
        },
        voidApplication(){
          this.dialogConfirmation = true
          this.void_reason = null
          this.action = 'void'
        },
        unvoidApplication(){
          this.dialogConfirmation = true
          this.action = 'unvoid'
        },
        makeDuplicate(){
          this.dialogConfirmation = true
          this.action = 'duplicate'
        },
        deleteApplication(){
          this.dialogConfirmation = true
          this.action = 'delete'
        },
        paidApplication(){
          this.dialogConfirmation = true
          this.action = 'paid'
        },
        generateIDAnalyzer(){
          this.dialogConfirmation = true
          this.action = 'generate_id_analyzer'
        },
        resubmitIDAnalyzer(){
          this.dialogConfirmation = true
          this.action = 'resubmit_id_analyzer'
        },
        confirmVoidApplication(){
          if (this.$refs.dialogForm.validate()) {
          let data = {
          token: this.data.token,
          void_reason: this.void_reason
          };
          this.axiosCall("/applications/void", "POST", data).then((res) => {
            if (res.data.status == 200) {
              this.initialize();
              this.showToast(res.data.msg, 'success')
            }
            else{
              this.showToast(res.data.msg, 'error')
            }
            this.dialogConfirmation = false
          });
          }
        },
        confirmUnvoidApplication(){
          let data = {
          token: this.data.token
          };
        this.axiosCall("/applications/unvoid", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.initialize();
            this.showToast(res.data.msg, 'success')
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
          this.dialogConfirmation = false
        });
        },
        confirmDuplicateApplication(){
          let data = {
          token: this.data.token
          };
        this.axiosCall("/applications/duplicate", "POST", data).then((res) => {
          if (res.data.status == 201) {
            this.initialize();
            this.showToast(res.data.msg, 'success')
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
          this.dialogConfirmation = false
        });
        },
        confirmDeleteApplication(){
          let data = {
          token: this.data.token
          };
        this.axiosCall("/applications/delete", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.initialize();
            this.showToast(res.data.msg, 'success')
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
          this.dialogConfirmation = false
        });
        },
        confirmPaidApplication(){
          let data = {
          token: this.data.token
          };
        this.axiosCall("/applications/markAsPaid", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.initialize();
            this.showToast(res.data.msg, 'success')
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
          this.dialogConfirmation = false
        });
        },
        confirmGenerateIDAnalyzer(){
          let data = {
          token: this.data.token
          };
        this.axiosCall("/applications/analyzer", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.initialize();
            this.showToast(res.data.msg, 'success')
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
          this.dialogConfirmation = false
        });
        },
        confirmResubmitIDAnalyzer(){
          let data = {
          token: this.data.token
          };
        this.axiosCall("/applications/analyzer-resubmit", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.initialize();
            this.showToast(res.data.msg, 'success')
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
          this.dialogConfirmation = false
        });
        },
        markAsSubmitted(){
          let data = {
          token: this.data.token
          };
        this.axiosCall("/applications/markAsSubmitted", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.showToast(res.data.msg, 'success')
            if(this.$store.state.user.role == 'agent' || this.$store.state.user.role == 'executive_supervisor'){
              this.$router.push('/' + this.$store.state.user.role + '/applications')
            }
            else{
              this.initialize();
            }
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
        });
        },
        initiateRealTimeData(){
          if(this.data.token){
          clearInterval(this.application_data_listener);
          this.application_data_listener = setInterval(() => {
            if(this.$route.path == '/' + this.$store.state.user.role + '/search/view/' + this.data.token + '/'){
            this.getRealTimeData()
            }
            else{
            clearInterval(this.application_data_listener);
            }
          }, 5000);
          }
        },
        getRealTimeData(){
          let data = {
          token: this.data.token,
          };
        this.axiosCall("/applications/data/real-time", "POST", data).then((res) => {
          if (res.data.status == 200) {
            let data = res.data.data;
            this.data.applicationEmailCode = data.application_email_code;
            this.data.applicationNumber = data.application_number;
            if(data.application_email_code && data.application_number){
              clearInterval(this.application_data_listener);
            }
          }
          // else{
          //   this.showToast(res.data.msg, 'error')
          // }
        });
        },
        getGovWebsite(type){
          let result = 'None'
          let websites = [
            { type: 'USA-ESTA', website: 'None' },
            { type: 'K-ETA', website: 'https://www.k-eta.go.kr/portal/apply/index.do' },
            { type: 'UK-EVW', website: 'https://apply-for-an-eta.homeoffice.gov.uk/apply/electronic-travel-authorisation/how-to-apply' },
            { type: 'VN-EVISA', website: 'https://evisa.xuatnhapcanh.gov.vn/en_US/web/guest/khai-thi-thuc-dien-tu/cap-thi-thuc-dien-tu' },
            { type: 'KH-EVISA', website: 'https://www.evisa.gov.kh/application_new' },
            { type: 'CA-ETA', website: 'https://onlineservices-servicesenligne.cic.gc.ca/eta/welcome?lang=en' },
            { type: 'NZ-ETA', website: 'None' },
          ]
          websites.forEach(item => {
            if(item.type == type){
              result = item.website;
            }
          });
          return result;
        },
        markDecisionAsRejected(){
          this.dialogConfirmation = true;
          this.action = 'decision_rejected';
        },
        confirmMarkDecisionAsRejected(){
        let data = {
          token: this.data.token,
          decision: 'travel-not-authorized'
          };
        this.axiosCall("/applications/decision", "POST", data).then((res) => {
          if (res.data.status == 200) {
            this.dialogConfirmation = false;
            this.initialize();
            this.showToast(res.data.msg, 'success')
          }
          else{
            this.showToast(res.data.msg, 'error')
          }
          this.loadingConfirm = false;
        });
       }
    },
    mounted(){
       this.initialize()
       this.getAccounts()
       this.eventHub.$on("reinitializeUS", () => {
        this.initialize();
        const el = document.querySelector("#applicant-passport-div");
        el.scrollIntoView({
            behavior: 'smooth'
        })
      });
    },
    beforeDestroy() {
      this.eventHub.$off("reinitializeUS");
    },
  }
</script>
<style scoped>
.view-main-title {
  color: #4d6585 !important;
  font-weight: 500;
  font-size: 16px;
}
.view-title {
  color: #4d6585 !important;
  font-weight: 500;
}
.chip-status-1{
  background-color: #d7f7c2 !important;
  color: #1d7327 !important;
}
.passport-img {
  max-width: 1000px;
  text-align: center;
  margin: 0 auto;height: 717px;
  background-size: cover;
  background-repeat: no-repeat;
  border-image-source: linear-gradient(to left, #146908, #d7f7c2) !important;
  border: 10px solid;border-image-slice: 1;
  border-width: 20px;
}
.passport-img-failed {
  max-width: 1000px;
  text-align: center;
  margin: 0 auto;height: 717px;
  background-size: cover;
  background-repeat: no-repeat;
  border-image-source: linear-gradient(to left, #c4093c, #ffe7f2) !important;
  border: 10px solid;border-image-slice: 1;
  border-width: 20px;
}
</style>