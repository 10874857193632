<template>
  <div>
    <v-container fluid class="content">
      <v-row align="center" justify="center">
        <v-col align="center" cols="12" md="6">
          <v-row>
          <v-col cols="12" class="mb-7">
            <v-img src="../../assets/img/vip-global-logo.svg" width="250" height="100" class="logo-style"></v-img>
          </v-col>
          </v-row>
          <v-card max-width="500" class="rounded-card" align="start">
            <v-form ref="Formref">
              <v-row class="pa-10">
                <v-col cols="12" class="pa-0 px-4 mb-10">
                  <div class="text-h4" color="#123E4D" align="center" style="font-family: 'Transport New', sans-serif !important;">
                    <b>Sign In</b>
                  </div>
                </v-col>
                <v-col cols="12" class="pa-0 px-4">
                  <div class="px-0 pb-2 text-h6" style="font-family: 'Transport New', sans-serif !important;">Your email</div>
                  <v-text-field
                    color="#377dff"
                    class="font-size-14"
                    v-model="email"
                    :rules="[formRules.required, formRules.email]"
                    placeholder="email@address.com"
                    outlined
                    dense
                    height="45"
                    @keyup.enter="dologin()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pa-0 px-4">
                  <div class="d-flex justify-space-between align-center">
                  <div class="px-0 pb-2 text-h6" style="font-family: 'Transport New', sans-serif !important;">Password</div>
                  <div class="font-size-13 pb-2">
                    <span class="pl-2 fp-style" @click="forgotPassword()">
                      Forgot Password?
                    </span>
                  </div>
                  </div>
                  <v-text-field
                    color="#377dff"
                    class="font-size-14"
                    v-model="password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[formRules.required]"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    placeholder="6+ characters required"
                    outlined
                    @keyup.enter="dologin()"
                    height="45"
                    @click:append="show1 = !show1"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <div class="d-flex justify-center">
                    <v-btn
                      block
                      depressed
                      x-large
                      color="#377dff"
                      class="white--text py-5 font-size-14"
                      :loading="isLoading"
                      @click="dologin()"
                      >Sign In</v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
export default {
  name: "login",
  mounted() {
    if (this.$store.state.user) {
      if (!this.$store.state.user.id) {
        //this.deleteToken();
        setTimeout(function () {
          location.reload();
        }, 0);
      }
    }
  },
  methods: {
    dologin() {
      if (this.$refs.Formref.validate()) {
        this.isLoading = true;
        let data = {
          email: this.email,
          password: this.password
          };
        this.axiosCall("/auth/login", "POST", data).then((res) => {
          this.isLoading = false;
          if (res.data.status == 200 || res.data.status == 201 || res.data.status == 202) {
            localStorage.setItem("token", res.data.access_token);
            location.reload();
          }
          else{
            this.showToast2("Invalid email or password!", 'error')
          }
        });
      }
    },
    forgotPassword() {
      this.$router.replace('/forgot-password')
    }
  },
  data: () => ({
    isLoading: false,
    email: "",
    password: "",
    show1: false,
    show2: false,
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
    },
  }),
};
</script>

<style scoped>
.content {
  /* background: url("../../assets/img/dsh-bg.png"); */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px;
}
.title {
  color: #fff;
  font-size: 35px !important;
  position: relative;
  font-weight: bold;
  line-height: 40px;
}
.title .underline {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.title .underline .u1 {
  background: #fff;
  width: 70%;
  height: 5px;
  margin: 0 5px;
}
.title .underline .u2 {
  background: #fff;
  width: 5%;
  height: 5px;
  margin: 0 5px;
}
.lp-img {
  -webkit-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.09));
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.09));
  border-radius: 50%;
  background: #fff;
}
.rounded-card {
  border-radius: 10px;
}
.fp-style{
  color: #377dff;
  cursor: pointer;
}
.fp-style:hover {
  color: #0047cc;
}
</style>
