<template>
  <div class="pa-5">
    <v-card class="card-style rounded-1" >
    <v-row class="ma-2">
      <v-col cols="12" md="6" class="flex-items">
        <h2>Agents</h2>
      </v-col>
      <v-col cols="12" sm="6" :align="currentSize >= 2 ? 'right' : 'center'" class="px-5 pt-4">
          <div class="drp-div-style">
            <date-range-picker class="drp-style" v-model="dateRange" format="MMM D, YYYY" :options="dr_options" />
            <v-icon class="pa-0">mdi-chevron-down</v-icon>
          </div>
      </v-col>
    </v-row>
    <v-card class="mb-1 dt-container" elevation="0" outlined>
    <v-data-table
        ref="dataTable"
        :loading="loading"
        :headers="headers"
        :items="data"
        :options.sync="options"
        hide-default-footer
    >
    <template v-slot:[`item.agent`]="{ item }">
       {{item.first_name + ' ' + item.last_name}}
    </template>
    
    </v-data-table>
    </v-card>
    <v-row class="ma-2" align="center" justify="center">
    <v-col cols="auto" class="flex-items">
        <span class="px-2">Row Per Page: </span>
        <span>
          <v-select
            dense
            outlined
            hide-details
            :value="options.itemsPerPage"
            style="max-width: 90px"
            class="rounded-lg"
            @change="options.itemsPerPage = parseInt($event, 10)"
            :items="perPageChoices"
          >
          </v-select>
        </span>
    </v-col>
    <v-col cols="auto" class="mx-auto text-truncate" no-gutters>
    Showing {{ data.length > 0 ? ((paginationData.currentPage - 1) * options.itemsPerPage) + 1 : 0 }} to {{ data.length > 0 ? ((paginationData.currentPage - 1) * options.itemsPerPage) + data.length : 0 }} of {{ paginationData.count }} entries
    </v-col>
    <v-col cols="auto">
        <v-pagination
            v-model="options.page"
            class="rounded-lg"
            :total-visible="7"
            color="#38435a"
            :length="paginationData.lastPage">
        </v-pagination>
    </v-col>
      </v-row>
    </v-card>
    <v-dialog
      v-model="dialogConfirmation"
      width="520"
      persistent
    >
      <v-card>
        <v-card-title class="text-h6">
          <strong>CONFIRMATION</strong>
        </v-card-title>

        <v-card-text>
            <div class="subtitle-1">
              Are you sure you want to {{edit_item.switch_enabled ? 'enable' : 'disable'}} <strong>{{edit_item.name}}</strong>?
            </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            outlined
            class="mr-2"
            depressed
            @click="cancelSwitch()"
          >
            Cancel
          </v-btn>
          <v-btn
            color="#166ecf"
            class="white--text"
            depressed
            @click="confirmSetClient()"
            v-if="edit_item.switch_enabled"
            :loading="loadingConfirm"
          >
            Yes, Enable
          </v-btn>
          <v-btn
            color="#d13c56"
            class="white--text"
            depressed
            @click="confirmSetClient()"
            v-if="!edit_item.switch_enabled"
            :loading="loadingConfirm"
          >
            Yes, Disable
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</div>
</template>
<script>
import moment from "moment-timezone";
  export default {
    props: {
      tab: null,
    },
    components: {
    },
    data: () => ({
      search: '',
      headers: [
          { text: 'AGENT', value: 'agent', width: 150 },
          { text: 'TOTAL', value: 'total_applications', align: 'center', width: 90},
          { text: '09:00', value: 'applications_per_hour.09', align: 'center', sortable: false, class: 'px-2'},
          { text: '10:00', value: 'applications_per_hour.10', align: 'center', sortable: false, class: 'px-2'},
          { text: '11:00', value: 'applications_per_hour.11', align: 'center', sortable: false, class: 'px-2'},
          { text: '12:00', value: 'applications_per_hour.12', align: 'center', sortable: false, class: 'px-2'},
          { text: '13:00', value: 'applications_per_hour.13', align: 'center', sortable: false, class: 'px-2'},
          { text: '14:00', value: 'applications_per_hour.14', align: 'center', sortable: false, class: 'px-2'},
          { text: '15:00', value: 'applications_per_hour.15', align: 'center', sortable: false, class: 'px-2'},
          { text: '16:00', value: 'applications_per_hour.16', align: 'center', sortable: false, class: 'px-2'},
          { text: '17:00', value: 'applications_per_hour.17', align: 'center', sortable: false, class: 'px-2'},
          { text: '18:00', value: 'applications_per_hour.18', align: 'center', sortable: false, class: 'px-2'},
          { text: '19:00', value: 'applications_per_hour.19', align: 'center', sortable: false, class: 'px-2'},
          { text: '20:00', value: 'applications_per_hour.20', align: 'center', sortable: false, class: 'px-2'},
          { text: '21:00', value: 'applications_per_hour.21', align: 'center', sortable: false, class: 'px-2'},
          { text: '22:00', value: 'applications_per_hour.22', align: 'center', sortable: false, class: 'px-2'},
          { text: '23:00', value: 'applications_per_hour.23', align: 'center', sortable: false, class: 'px-2'},
          { text: '00:00', value: 'applications_per_hour.00', align: 'center', sortable: false, class: 'px-2'},
          { text: '01:00', value: 'applications_per_hour.01', align: 'center', sortable: false, class: 'px-2'},
          { text: '02:00', value: 'applications_per_hour.02', align: 'center', sortable: false, class: 'px-2'},
          { text: '03:00', value: 'applications_per_hour.03', align: 'center', sortable: false, class: 'px-2'},
          { text: '04:00', value: 'applications_per_hour.04', align: 'center', sortable: false, class: 'px-2'},
          { text: '05:00', value: 'applications_per_hour.05', align: 'center', sortable: false, class: 'px-2'},
          { text: '06:00', value: 'applications_per_hour.06', align: 'center', sortable: false, class: 'px-2'},
          { text: '07:00', value: 'applications_per_hour.07', align: 'center', sortable: false, class: 'px-2'},
          { text: '08:00', value: 'applications_per_hour.08', align: 'center', sortable: false, class: 'px-2'},
          // { text: "", value: "data-table-expand", width: 1, align: 'center', sortable: false }
        ],
      data: [],
      perPageChoices: [
          {text:'5' , value: 5},
          {text:'10' , value: 10},
          {text:'20' , value: 20},
          {text:'50' , value: 50},
          {text:'100' , value: 100},
          {text:'250' , value: 250},
          {text:'500' , value: 500},
      ],
      options: {
        itemsPerPage: 20
      },
      options2: {},
      options3: {},
      paginationData: {},
      loading: false,
      formdata: [],
      loadingConfirm: false,
      edit_item: {},
      dialogConfirmation: false,
      dateRange: [],
      dr_options: {
        ranges: {
        'Today': [moment().tz('America/New_York'), moment().tz('America/New_York')],
        'Yesterday': [moment().tz('America/New_York').subtract(1, 'days'), moment().tz('America/New_York').subtract(1, 'days')],
        'Last 7 Days': [moment().tz('America/New_York').subtract(6, 'days'), moment().tz('America/New_York')],
        'Last 30 Days': [moment().tz('America/New_York').subtract(29, 'days'), moment().tz('America/New_York')],
        'This Month': [moment().tz('America/New_York').startOf('month'), moment().tz('America/New_York').endOf('month')],
        'Last Month': [moment().tz('America/New_York').subtract(1, 'month').startOf('month'), moment().tz('America/New_York').subtract(1, 'month').endOf('month')]
        },
        "buttonClasses": "btn btn-lg",
        "applyButtonClasses": "btn-primary"
        },
      startDate: null,
      endDate: null,
      expanded: [],
      singleExpand: true,
      expanded2: [],
      singleExpand2: true,
    }),
    watch: {
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      dateRange: {
        handler(data){
          if(data.length == 2){
          var date1 = new Date(data[0]);
          this.startDate = parseInt(date1.getDate()) + '-' + parseInt(String(date1.getMonth() + 1).padStart(2, '0')) + '-' + date1.getFullYear();
          var date2 = new Date(data[1]);
          this.endDate = parseInt(date2.getDate()) + '-' + parseInt(String(date2.getMonth() + 1).padStart(2, '0')) + '-' + date2.getFullYear();
          this.initialize();
          }
        }
      }
    },
    computed: {
      currentSize() {
        const breakpoint = this.$vuetify.breakpoint;
        if (breakpoint.xs) return 1;
        if (breakpoint.sm) return 2;
        if (breakpoint.md) return 3;
        if (breakpoint.lg) return 4;
        if (breakpoint.xl) return 5;
        return 0;
      }
    },
    mounted() {
      var date1 = new Date();
      this.startDate = parseInt(date1.getDate()) + '-' + parseInt(String(date1.getMonth() + 1).padStart(2, '0')) + '-' + date1.getFullYear();
      var date2 = new Date();
      this.endDate = parseInt(date2.getDate()) + '-' + parseInt(String(date2.getMonth() + 1).padStart(2, '0')) + '-' + date2.getFullYear();
      this.initialize();
    },
    methods: {
      initialize (){
        if(this.startDate && this.endDate){
        this.loading = true;
        let page = this.options.page;
        let itemPerPage = this.options.itemsPerPage;
        let sortBy = this.options.sortBy.length > 0 ? this.options.sortBy[0] : '';
        let order = this.options.sortBy.length > 0 ? this.options.sortDesc[0] ? 'desc' : 'asc' : '';
        let search = this.search;
        let startDate = this.startDate;
        let endDate = this.endDate;
        this.axiosCall('/applications/getAgentsTransaction?page=' + page + '&itemPerPage=' + itemPerPage + '&sortBy=' + sortBy + '&order=' + order + '&searchKey=' + search + '&startDate=' + startDate + '&endDate=' + endDate, 'GET').then((res) => {
              if(res.data){
                // console.log(res.data)
              this.loading = false
              this.data = res.data.data || [];
              
              this.paginationData = res.data.pagination;
              }
          })
        }
      },
    }
  }
</script>
<style scoped>
.chip-status-1{
  background-color: #d7f7c2 !important;
  color: #2f8547 !important;
}
.chip-status-2{
  background-color: #cff5f6 !important;
  color: #1066c5 !important;
}
.chip-status-3{
  background-color: #ebeef1 !important;
  color: #616f7e !important;
}
.chip-status-4{
  background-color: #fcedb9 !important;
  color: #b02c00 !important;
}
.chip-status-5{
  background-color: #ffe7f2 !important;
  color: #c4093c !important;
}
.chip-status-6{
  background-color: #F0FFF8 !important;
  color: #18AB56 !important;
}
</style>