<template>
  <v-app class="" style="font-family: 'Transport New', sans-serif !important">
    <Navbar/>
    <v-main class="DarkWhite">
    <!-- <v-row>
      <v-col cols="12" class="pt-10 px-10">
        <h2>{{$route.meta.title}}</h2>
      </v-col>
    </v-row> -->
      <router-view/>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
  },
  data: () => ({
    //
  }),
  methods: {
  }
};
</script>
